@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  color:#1D1C26;
  scroll-behavior: smooth;
}


img {
  max-width: 92%;
  height: auto;
  float: right;
}
.App {
  /* min-height: 100vh; */
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 1280px;
  overflow-x: hidden;
  background-color: #f6f6f6;
  margin: auto;
  height: auto;
}
nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 70px;
  padding-left: 70px;
  padding-right: 70px;
  position: sticky;
  top: 0;
  background-color: #f6f6f6;
  z-index:99;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: #1D1C26;
  font-size: 1rem;
  font-weight: 300;
}
.navbar-links-container a:hover {
  color: #1d1c2668;
}
.navbar-cart-container{
  min-width: 40px;
  margin: 0 auto;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  border-radius: 0;
  /* appearance: none; */
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: #1D1C26;
  color:#f6f6f6;
  outline: none;
  border: 2px solid #1D1C26;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.primary-button:hover {
  padding: 0.9rem 1.75rem;
  background-color: #f6f6f6;
  color: #1D1C26;
  outline: none;
  border: 2px solid #1D1C26;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 1rem;
  margin-left: 120px;
  margin-right: 120px;
}
.home-container{
  padding-bottom: 70px;
  background-color: #f6f6f6;
}
.home-image-section {
  max-width: 70%;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
}
.primary-heading {
  font-family: 'Playfair Display';
  font-size: 3.81rem;
  color: #1D1C26;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: 1.25rem;
  max-width: 500px;
  color: #1D1C26;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #f6f6f6;
  outline: none;
  border: 2px solid #1D1C26;
  border-radius: 5rem;
  font-size: 1.25rem;
  cursor: pointer;
  font-weight: 600;
  color: #1D1C26;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #1D1C26;
  color: #f6f6f6;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

/*popular books home section*/
.popular-books-card{
display: flex;
flex-direction: column;
background-color: #f6f6f6;
padding-bottom: 20px;
}
.popular-books-card-header{
  display: flex;
  flex-direction: row;
  /* margin-left: 0px;
  margin-right: 120px;
  margin-top: 30px;
  font-weight: 800;
  justify-content: space-between; */
  justify-content: center;
}
.popular-books-card-header-title{
  font-size: 1.6rem;
  margin-left: 162px;
}
.popular-books-card-books{
display: flex;
flex-direction: row;
justify-content: space-evenly;
/* gap: 110px; */
padding-top: 20px;
padding-bottom: 20px;
/* margin-left: 120px;
margin-right: 120px; */
align-items: flex-start;
margin-bottom: 25px;
}
.popular-books-card-more{
  font-size: 1.2rem;
  font-weight: 500;
  color: #2D9AA6;
cursor: pointer;

}
.book-card-info-title-top{
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

/*book cards*/

.book-image{
  max-width: 250px;
  height: auto;
  border-radius: 10px;
}
.book-card{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 240px;
  margin-top: 30px;
  justify-content: start;
  /* align-items: center; */
  border-radius: 10px;
  padding: 10px;
}
.book-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.book-card:hover .book-card-info-title-top{
  text-decoration: underline;
}
.book-card:hover .book-card-top-image{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.book-card-top-image{
  max-width: 75vw;
  max-height: 45vh;
  margin-bottom: 5%;
  border-radius: 10px;
}
.book-card-info-price-top{
  font-size: 1rem;
  font-weight: 600;
  color: #2D9AA6;
  margin-bottom: 5%;
}
.book-card-info-title-top{
  font-size: 1rem;
  font-weight: 400;
  height: 60px;
  text-align: left;
}
.book-card-info-type-top{
  font-size: 0.7rem;
  margin-bottom: 5%;
  background-color: #f6f6f6;
  width: 45px;
  text-align: center;
  border: 0.1px solid #1d1c2683;
  color: #1d1c2683;
  border-radius: 5rem;
}

/*about section*/

.about{
  display: flex;
  flex-direction: row;
  margin-left: 120px;
  margin-right: 120px;
  justify-content: flex-start;
  gap: 60px;
  padding-top: 50px;
  color:#1D1C26;
}
.about-image{
  width: 400px;
  height: auto;
}
.about-info{
  width: 400px;
  bottom: 0;
  position: relative;
}

.about-header{
  font-family: 'Playfair Display';
  font-size: 3.81rem;
  color: #1D1C26;
  max-width: 600px;
  padding-top: 40px;
}

.about-text{
  position: absolute;
  bottom: 0;
}

.footer-wrapper {
  margin-left: 120px;
  margin-right: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  margin-top: 10rem;
  border-top: 2px solid #1D1C26;
  color:#1D1C26;
}
.footer-logo-container {
  max-width: 110px;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
}

.terms{
  margin-left: 10%;
  width: 80%;
  margin-top: 10%;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-one {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  opacity: 60%;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
}

.footer-section-row {
  display: flex;
  flex-direction: row;
  gap: 40px;
  min-width: 190px;
  opacity: 60%;
}
.footer-section-row span{
  margin: 0.25rem 0rem;
  font-weight: 600;
  cursor: pointer;
}

/*store*/

.store-products{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  height: auto;
}

.store-header{
  font-family: 'Playfair Display';
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 45px;
}

.store-row{
display: flex;
flex-direction: row;
justify-content: space-between;
flex-wrap: wrap;
row-gap: 50px;
gap: 20px;
}

.product-card{
  /* flex: 50%; */
}

.product-card-content{
  display: flex;
  flex-direction: row;
  /* margin-bottom: 40px; */
  border: 1px solid #1d1c2629;
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 10px 10px 10px 10px;
}

.book-card-image{
  max-width: 50vw;
  max-height: 30vh;
  border-radius: 10px;
}


.book-card-info{
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 220px;
  gap: 6px;
  position: relative;
  margin-top: 20px;
}

.book-card-info-title{
  font-size: 18px;
  font-weight: 500;
  padding-left: 16px;
}


.book-card-info-type{
  font-size: 12px;
  width: 60px;
  text-align: center;
  color: #1d1c2683;
  padding-left: 16px;
}

.book-card-info-price{
  font-size: 26px;
  font-weight: 600;
  color: #2D9AA6;
  padding-left: 16px;
 bottom: 0px;
} 

.book-card-button{

}

.primary-button-buy{
  padding: 0.8rem 1rem;
  background-color: #1D1C26;
  color:#f6f6f6;
  outline: none;
  border: 2px solid #1D1C26;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  /* margin-bottom: 0px; */
  width: 100%;
  position: absolute; 
  bottom: 0px;
}

.primary-button-buy:hover{
  padding: 0.6rem 1.45rem;
  background-color: #f6f6f6;
  color:#1D1C26;
  outline: none;
  border: 2px solid #1D1C26;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

/* book description */
.book-about{
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  height: auto;
}
.book-about-return{
margin: 20px;
scroll-margin-top: 90px;

}
.book-about-section{
  display: flex;
  flex-direction: column;
}
.book-about-section-top{
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  border-bottom: 1px solid #DFDEDE;
  justify-content: center;
}
.book-about-title{
  font-family: 'Playfair Display';
  font-size: 2.441rem;
  color: #1D1C26;
  font-weight: 500;
}

.book-about-image{
  /* max-height: 20%; */
  max-height: 76vh;
  max-width: 28vw;
  min-width: 56vh;
  min-height: 18vw;
  /* border-radius: 50px;
  background-color: #DFDEDE; */
}
.book-about-image img{
  /* max-height: 20%; */
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}
.book-about-tags{
  display: flex;
  flex-direction: row;
  gap: 2%;
}
.book-about-tag{
  font-size: 0.7rem;
  margin-bottom: 2%;
  background-color: white;
  width: 45px;
  text-align: center;
  border: 0.1px solid #1d1c2683;
  color: #1d1c2683;
  border-radius: 5rem;
}
.book-about-additional-info{
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.book-about-section-top-price{
  font-size: 2rem;
  font-weight: 600;
  color: #2D9AA6;
  margin-bottom: 3%;
  /* font-family: 'Mulish'; */
}

.book-description{
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  row-gap: 15px;
  max-width: 35vw;
  margin-left: 8%;
  justify-content: flex-end;
}

.primary-button-add-to-cart {
  padding: 0.9rem 1.75rem;
  background-color: #1D1C26;
  color:#f6f6f6;
  outline: none;
  border: 2px solid #1D1C26;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  max-width: 200px;
}

.book-about-section-bottom{
  display: flex;
  flex-direction: column;
}
.book-about-section-bottom-headers{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 200px;
  margin: 20px;
}
.book-about-section-bottom-headers{
  display: flex;
  flex-direction: row;
}

.primary-button-add-to-cart:hover {
  padding: 0.9rem 1.75rem;
  background-color: #f6f6f6;
  color: #1D1C26;
  outline: none;
  border: 2px solid #1D1C26;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.book-about-section-bottom-description{
/* white-space: pre-wrap;
word-break: break-all; */
margin-top: 40px;
width: 100%;
}
.book-about-section-bottom-description-item{
  white-space: pre-wrap;
  }

/* cart */
.cart{
  margin-left: 120px;
  /* margin-right: 120px; */
  margin-top: 50px;
}
.cart-header{
  font-size: 24px;
}
.cart-all{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cart-container{
  /* margin-left: 120px; */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 60%;
}
.cart-item{
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: #67658651 solid 1px;
}
.cart-item-img{
  max-width: 50vw;
  max-height: 20vh;
  border-radius: 10px;
}
.cart-item-title{
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 50px;
  width: 400px;
  padding-top: 50px;
}
.cart-item-price{
  font-size: 1.2rem;
  font-weight: 1000;
  margin-left: 50px;
  min-width: 100px;
  padding-top: 50px;  
}
.remove-item{
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 0;
  margin-left: 100px;
}
.cart-checkout{
  margin-right: 120px;
  margin-left: 20px;
  min-width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #dfdede66;
  border-radius: 10px;
}
.cart-checkout-total{
  margin-bottom: 5%;
  font-size: 12.80px;
}
.cart-checkout-price{
  font-size: 31.25px;
  color: #2D9AA6;
  font-weight: 1000;
  margin-bottom: 5%;
}
input[type=email]{
  border: none;
  height: 40px;
  width: 100%;
  background-color: #f6f6f6;
  border-bottom: 1px solid #1D1C26;
  font-size: 16px;
}
.cart-button{
  margin-top: 10%;
  padding: 0.9rem 1.75rem;
  background-color: #2D9AA6;
  color:#f6f6f6;
  outline: none;
  border: 2px solid #2D9AA6;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.cart-button:hover{
  padding: 0.9rem 1.75rem;
  background-color: #f6f6f6;
  color:#2D9AA6;
  outline: none;
  border: 2px solid #2D9AA6;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.cart-button:disabled{
  margin-top: 10%;
  padding: 0.9rem 1.75rem;
  background-color: #1d1c2629;
  color:#f6f6f6;
  outline: none;
  border: 2px solid #1d1c2629;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.item-count {
  content:attr(value);
  font-size:12px;
  color: #f6f6f6;
  background: #2D9AA6;
  border-radius:50%;
  padding: 0 5px;
  position:relative;
  left:-8px;
  top:-10px;
  opacity:0.9;
}

.zamawiam{
  text-decoration: none;
  color: #f6f6f6;
}

.zamawiam:hover{
  text-decoration: none;
  color: #2D9AA6
}





/* @media (max-width: 800px) {
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: block;
  }
  .home-banner-container{
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .home-bannerImage-container {
    padding: 0;
  }
  .home-text-section{
    font-size: 1.2rem;
    padding-top: 28px;
  }
  .popular-books-card{

  }
  .popular-books-card-header{
    flex-direction: column;
    justify-content: center;
  }
  .popular-books-card-books{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .book-card{
    min-width: 280px;
  }
  .book-card-top-image{
    max-width: 280px;
  }
  .popular-books-card-header{
    flex-direction: column;
    justify-content: center;
  }
  .about{
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;

  }
  .about-image{
    max-width: 220px;
  }
  .about-header{
    padding-top: 0px;
    margin-bottom: 100px;
  }
  .footer-wrapper {
    flex-direction: row;
  }
  .footer-section-one{
    margin-top: 0px;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 0;
  }
  .footer-section-row{
    flex-direction: column;
    align-items: end;
    gap: 18px;
    color:#1d1c2665;
  }
  .footer-section-columns {
    
  }
  .book-about-section-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .book-about-title{
    font-size: 2rem;
  }
  .book-about-image{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .book-about-image img{
    height: 80%;
    width: 80%;
  }
  .book-about-section-bottom{
    background-color: blue;
    display: flex;
  
    width: 100%;
  }
.book-about-section-bottom-description{

}
.book-about-section-bottom-description-item{

  
}

.cart-all{
  display: flex;
  flex-direction: column;
  gap:80px;
  justify-content: flex-start;
  align-items: flex-start;
}
.cart-container{

}
.cart-item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  background-color: red;
}
.cart-item-price{
 
}
.remove-item{

}
.cart-checkout{
  border: solid #1d1c2683 1px;
  border-radius: 10px;
}
} */

@media (max-width: 480px) {
  *{
      /* box-sizing: border-box; */
      font-family: 'Mulish', sans-serif;
      color: #1d1c26;
  }

  body {
    background-color: #f6f6f6;
    margin: 0 auto;
  }

  img {
    max-width: 100%;
    height: auto;
    }

    .App {
      
    }

    .navbar-links-container {
      display: none;
    }
    .navbar-menu-container {
      display: flex;
    }
    .home-container{
      display: flex;
      flex-direction: column;
      width: calc(100vw - 80px);
      margin-left: 40px;
      margin-right: 40px;
    }
    .home-banner-container {
     display: flex;
     flex-direction: column-reverse;
     margin: auto;
    }
    .home-image-section {
     
    }
    .home-text-section {
      margin: 0 auto;  
      padding-top: 10px;
    }
    .primary-heading {
      font-size: 1.75rem;
    }
    .primary-text {
      font-size: 1.25rem;
      max-width: 500px;
      color: #1D1C26;
      /* margin: 1.5rem 0rem; */
    }
    .primary-button {
      padding: 0.9rem 1.75rem;
      background-color: #1D1C26;
      color:#f6f6f6;
      outline: none;
      border: 2px solid #1D1C26;
      border-radius: 5rem;
      font-size: 10px;
      font-weight: 600;
    }
    .secondary-button {
      padding: 1rem 2.5rem;
      background-color: #f6f6f6;
      outline: none;
      border: 2px solid #1D1C26;
      border-radius: 5rem;
      font-size: 1.0rem;
      cursor: pointer;
      font-weight: 600;
      color: #1D1C26;
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    /*popular books home section*/
.popular-books-card{
 display: flex;
 flex-direction: column;
}
.popular-books-card-header{
 
}
.popular-books-card-header-title{

}
.popular-books-card-books{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.popular-books-card-more{
  display: none;
}

/* .book-card-info-title-top{
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
} */

/*book cards*/

.book-image{
max-width: 260px;
height: auto;
border-radius: 10px;
}

.book-card{
display: flex;
flex-direction: column;
height: auto;
width: 260px;
margin-top: 30px;
justify-content: start;
align-items: start;
}

.book-card-top-image{
max-width: 240px;
max-height: auto;
margin-bottom: 5%;
border-radius: 10px;
}
.book-card-info-price-top{
font-size: 1rem;
font-weight: 600;
color: #2D9AA6;
margin-bottom: 10%;
}
.book-card-info-title-top{
font-size: 1rem;
font-weight: 400;
height: 80px;
}
.book-card-info-type-top{
font-size: 0.7rem;
margin-bottom: 5%;
background-color: white;
width: 45px;
text-align: left;
border: 0.1px solid #1d1c2683;
color: #1d1c2683;
border-radius: 5rem;
}

  /* about the author */

    .about{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 400px;
      margin-left: 16px;
      /* padding-top: 50px; */
      color:#1D1C26;
    }
    .about-image{
      width: 260px;
      height: auto;
      margin-left: 50px;
    }
    .about-info{
      display: flex;
      flex-direction: column;
    }
    
    .about-header{
      font-family: 'Playfair Display';
      font-size: 36px;
      color: #1D1C26;
      margin-bottom: 140px;
      overflow: hidden;
      margin-left: 20px;

    }
    
    .about-text{
      font-size: 16px;
      max-width: 80%;
      margin-left: 20px;
    }

    .terms{
      margin-left: 10%;
      width: 80%;
      margin-top: 10%;
    }
/* /////////////////////////////////////////////////////////////////// */
    .about-section-container {
      margin-top: 20rem;
      /* position: relative; */
      display: flex;
      flex-direction: column;
      /* align-items: center;
      justify-content: space-between; */
    }

    .about-background-image-container {
      position: absolute;
      left: -150px;
      z-index: -2;
    }
    .about-section-image-container {
      flex: 0.9;
      margin-right: 1rem;
    }
    .about-section-text-container {
      flex: 1;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .primary-subheading {
      font-weight: 700;
      color: #fe9e0d;
      font-size: 1.15rem;
    }
    .about-buttons-container {
      margin-top: 2rem;
      display: flex;
    }

    /* footer section */

    .footer-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      margin-top: 10rem;
      border-top: 2px solid #1D1C26;
      color:#1D1C26;
      margin-left: 28px;
      margin-right: 28px;
      
    }
    .footer-logo-container {
      max-width: 110px;
    }
    
    .footer-icons svg {
      font-size: 1.5rem;
      margin-right: 1.25rem;
    }
    .footer-section-one {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .footer-section-two {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      gap: 10px;
    }
    .footer-section-columns {
      display: flex;
      flex-direction: column;
      min-width: 220px;
      opacity: 60%;
    }
    .footer-section-columns span {
      margin: 0.25rem 0rem;
      font-weight: 600;
    }
    
    .footer-section-row {
      display: flex;
      flex-direction: column;
      gap: 40px;
      min-width: 220px;
      opacity: 60%;
    }
    .footer-section-row span{
      margin: 0.25rem 0rem;
      font-weight: 600;
      cursor: pointer;
    }

    /* store */

    .store-products{
      display: flex;
      flex-direction: column;
      width: calc(100vw - 40px);
      margin-left: 20px;
      margin-right: 20px;
      height: auto;
    }
      
    
      
      .product-card{
        /* flex: 50%; */
        width: 500px;
      }
      
      .product-card-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* margin-bottom: 40px; */
        border: 1px solid #1d1c2629;
        border-radius: 10px;
        gap: 16px;
        width: 240px;
      }
      
      .book-card-image{
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-top: 12px;
      }
      
      .book-card-info{
        display: flex;
        flex-direction: column;
        text-decoration: none;
        width: 100%;
        gap: 6px;

      }
      
      .book-card-info-title{
        font-size: 16px;
        font-weight: 500;
      }
      
      
      .book-card-info-type{
        font-size: 0.8rem;
        width: 60px;
        text-align: center;
        color: #1d1c2683;
      }
      
      .book-card-info-price{
        font-size: 20px;
        font-weight: 600;
        color: #2D9AA6;
        margin-bottom: 60px;
      } 
      
      .book-card-button{
  
      
      }
      
      .primary-button-buy{
        padding: 0.8rem 1rem;
        background-color: #1D1C26;
        color:#f6f6f6;
        outline: none;
        border: 2px solid #1D1C26;
        font-size: 0.8rem;
        cursor: pointer;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-weight: 600;
        transition: 0.2s;
        width: 100%;
      }

      /* cart */
.cart{
margin-top: 50px;
margin-left: 20px;
width: 88vw;
}
.cart-header{
font-size: 24px;
}
.cart-all{
display: flex;
flex-direction: column;
margin-left: 20px;
margin-right: 20px;

}
.cart-container{
display: flex;
margin-left: 0px;
margin-right: 0px;
}
.cart-item{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid #1d1c2629;
border-radius: 10px;
padding: 10px;
width: 78vw;
}
.cart-item-img{
width: 30px;
height: auto;
border-radius: 10px;
}
.cart-item-title{
font-size: 16px;
font-weight: 400;
width: 100%;
}

.cart-item-price{
font-size: 1.2rem;
font-weight: 1000;
}
.remove-item{
background-color: transparent;
cursor: pointer;
margin: 0px;
margin-top:20px;

}
.cart-checkout{
margin-left: 0px;
margin-right: 0px;
margin-top: 40px;
padding: 20px;
display: flex;
flex-direction: column;
}
.cart-checkout-total{
margin-bottom: 5%;
font-size: 12.80px;
}
.cart-checkout-price{
font-size: 31.25px;
color: #2D9AA6;
font-weight: 1000;
margin-bottom: 5%;
}
input[type=email]{
border: none;
height: 40px;
width: 100%;
background-color: #f6f6f6;
border-bottom: 1px solid #1D1C26;
font-size: 16px;
}
.cart-button{
margin-top: 10%;
padding: 0.9rem 1.75rem;
background-color: #2D9AA6;
color:#f6f6f6;
outline: none;
border: 2px solid #2D9AA6;
border-radius: 5rem;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s;
}
.cart-button:hover{
padding: 0.9rem 1.75rem;
background-color: #f6f6f6;
color:#2D9AA6;
outline: none;
border: 2px solid #2D9AA6;
border-radius: 5rem;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s;
}
.item-count {
content:attr(value);
font-size:12px;
color: #f6f6f6;
background: #2D9AA6;
border-radius:50%;
padding: 0 5px;
position:relative;
left:-8px;
top:-10px;
opacity:0.9;
}

/* book about */

.book-about{
display: flex;
flex-direction: column;
margin-left: 28px;
width: 340px;
}
.book-about-return{
margin-bottom: 20px;

}
.book-about-section{
display: flex;
flex-direction: column;
}
.book-about-section-top{
display: flex;
flex-direction: column;
padding-bottom: 50px;
border-bottom: 1px solid #DFDEDE;
}
.book-about-title{
font-family: 'Playfair Display';
font-size: 24px;
color: #1D1C26;
font-weight: 500;
width: 260px;
margin-top: 20px;
}

.book-about-image{
/* max-height: 20%; */
display: flex;
flex-direction: row;
background-color: #f6f6f6;
}
.book-about-image img{
/* max-height: 20%; */
width: 260px;
height: auto;
border-radius: 10px;
margin-left: 20px;
/* box-shadow: 10px 10px 5px 12px #1d1c260e; */
}
.book-about-tags{
display: flex;
flex-direction: row;
gap: 2%;
}
.book-about-tag{
font-size: 0.7rem;
margin-bottom: 2%;
background-color: white;
width: 45px;
text-align: center;
border: 0.1px solid #1d1c2683;
color: #1d1c2683;
border-radius: 5rem;
}
.book-about-additional-info{
display: flex;
flex-direction: row;
gap: 30px;
}
.book-about-section-top-price{
font-size: 2rem;
font-weight: 600;
color: #2D9AA6;
margin-bottom: 3%;
font-family: 'Playfair Display';
}

.book-description{
display: flex;
flex-direction: column;
font-size: 1rem;
row-gap: 15px;
max-width: 35vw;
justify-content: flex-end;
}

.primary-button-add-to-cart {
padding: 0.9rem 1.75rem;
background-color: #1D1C26;
color:#f6f6f6;
outline: none;
border: 2px solid #1D1C26;
border-radius: 5rem;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s;
width: 260px;
}

.book-about-section-bottom{
display: flex;
flex-direction: column;
}
.book-about-section-bottom-headers{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 100px;
margin: 20px;
}
.book-about-section-bottom-headers{
display: flex;
flex-direction: row;
}

.primary-button-add-to-cart:hover {
padding: 0.9rem 1.75rem;
background-color: #f6f6f6;
color: #1D1C26;
outline: none;
border: 2px solid #1D1C26;
border-radius: 5rem;
font-size: 1rem;
cursor: pointer;
font-weight: 600;
transition: 0.2s;
}

.book-about-section-bottom-description{
white-space: pre;
margin-top: 40px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 0;
margin: 0;
}

.book-about-section-bottom-description-item{
white-space: pre-wrap;
width: 100%;

}


  
}